<template>
  <div class="row px-md-4">
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="mb-40px mt-14px">
          <router-link to="/settings" class="return-btn"
            ><i class="far fa-arrow-left"></i> Back to all settings</router-link
          >
        </div>
        <h2 class="main-title">
          Upload Reports
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <div
          class="card p-60px"
          :class="[height_card < 650 ? 'pb-450px' : '']"
          ref="card"
        >
          <div class="row" v-if="loading == true">
            <div class="col-12 col-sm-12">
              <grid-loader
                class="expertel-loader"
                color="#ff502d"
                :loading="loading"
                :size="20"
                sizeUnit="px"
              ></grid-loader>
            </div>
          </div>
          <div class="row" v-if="loading == false">
            <div class="col-12 col-sm-12 col-lg-5 col-xl-4">
              <form
                class="settings-form d-flex flex-column"
                action="#"
                @submit.prevent="uploadReports()"
              >
                <label for="" class="settings-label">Account</label>
                <div class="input-container">
                  <select
                    v-model="indexData"
                    class="select-input"
                    name="carrier"
                    id="carrier"
                  >
                    <option
                      v-for="(item, key) in account_data"
                      :key="key"
                      :value="item.id"
                    >
                      {{ item.provider }} ( {{ item.account_number }} )
                    </option>
                    <!--
                      <option value="1">Telus (17512741)</option>
                      <option value="1">Bell (17512741)</option>
                      <option value="1">AT&T (17512741)</option>
                      <option value="1">Telus (54856984)</option>
                      <option value="1">Bell (35845985)</option>
                      <option value="1">AT&T (56941254)</option>
                      -->
                  </select>
                </div>

                <div class="">
                  <div
                    class="drop-upload"
                    @dragover="dragover"
                    @dragleave="dragleave"
                    @drop="drop"
                  >
                    <input
                      type="file"
                      multiple
                      name="fields[assetsFieldHandle][]"
                      id="assetsFieldHandle"
                      @change="onChange"
                      ref="file"
                    />
                    <p>Drop files here to upload</p>
                    <ul
                      class="file-list mt-4"
                      v-if="this.filelist.length"
                      v-cloak
                    >
                      <li
                        class="text-sm p-1"
                        v-for="(file, key) in filelist"
                        :key="key"
                      >
                        {{ file.name
                        }}<button
                          type="button"
                          @click="remove(filelist.indexOf(file))"
                          title="Remove file"
                        >
                          <i class="fal fa-times color-blue"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <!--
                    <button
                      class="button-expertel mr-3"
                      @click.prevent="uploadReports()"
                    >
                      Upload
                    </button>
                    <button class="btn btn-outline-expertel rounded-0">
                      Clear
                    </button>
                    -->
                </div>

                <div class="d-flex align-items-center mt-4">
                  <button type="submit" class="button-expertel mt-0">
                    Upload
                  </button>
                  <p class="color-orange mb-0 ml-3">
                    or
                    <router-link
                      :to="'/settings/single_upload'"
                      class="link color-orange"
                      >single file uploader</router-link
                    >
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      height_card: 0,
      loading: true,
      notification_settings: [],
      account_data: [],
      indexData: 0,
      filelist: []
      //files_uploads: [],
    };
  },
  async created() {
    const self = this;
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        // this.calculateHeight();
        this.loading = false;
      }
    } else {
      this.$router.push("/");
    }

    //GET THE COST CENTRE DATA AND SAVE IT IN [manager_centres]
    await this.axios({
      method: "get",
      url: `https://api.expertel.ca/api/v1/client/me/accounts`,
      responseType: "json",
      headers: {
        Authorization: "Bearer " + self.login_data.token
      }
    })
      .then(async function(response) {
        self.account_data = response.data;
      })
      .catch(error => this.makeToast("ERROR", error, "danger"));
  },
  methods: {
    calculateHeight() {
      this.height_card = this.$refs.card.clientHeight;
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
      //console.log(this.filelist);

      /*
      this.filelist.forEach(function(data) {
       this.files_uploads.push(data);
      });
      */
      //console.log(file);
      //this.files_uploads = event.dataTransfer.files[0];
      //console.log(this.files_uploads);
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(event); // Trigger the onChange event manually
    },
    uploadReports() {
      const self = this;

      if (!self.indexData) {
        self.makeToast("ERROR", "Select an account", "danger");
        return false;
      }

      if (this.filelist.length == 0) {
        self.makeToast("ERROR", "Select at least a file", "danger");
        return false;
      }

      this.loading = true;

      //FROMDATA TO SEND
      let formData = new FormData();
      this.filelist.forEach(function(data) {
        formData.append("file[]", data);
      });

      this.axios({
        method: "post",
        //url: `https://api.expertel.ca/api/v1/client/me/logo`,
        url: `https://api.expertel.ca/api/v1/client/me/accounts/${self.indexData}/file_upload`,
        responseType: "json",
        data: formData,
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          if (response.data.success == false) {
            self.makeToast("ERROR", response.data.errors, "danger");
            self.loading = false;
          } else {
            self.makeToast(
              "SUCCESS",
              "Reports uploaded successfully",
              "success"
            );
            self.filelist = "";
            self.loading = false;
          }
        })
        .catch(
          error => self.makeToast("ERROR", error, "danger"),
          (self.loading = false)
        );
    }
  }
};
</script>
